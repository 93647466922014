<template>
  <div>
    <div v-if="unityEdit">
      <div class="mainTitle textAverage">{{title}}</div>
      <div class="form">
        <div class="column">
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">Cliente: </label>
            </div>
            <div class="colImput">
              <div class="selectConfig">
                <div class="selectBtn">
                  <div class="eventClickArea" @click="showPopup()"></div>
                  <input class="input textSmall" :class="{inputDisabled: clientEdit}" style="cursor: pointer;" type="text" v-model="client.name" disabled>
                  <span v-if="!clientEdit" class="pointer">
                    <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                  </span>
                  <span v-else class="pointerDisabled">
                    <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                  </span>
                </div>
              </div>
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgClient}}</span>
            </div>
          </div>
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">CNPJ: </label>
            </div>
            <div class="colImput">
              <div class="selectConfig">
                <div class="selectBtn">
                  <input class="input textSmall" type="text" v-model="cnpj" @input="formatCNPJ()" @blur="searchCNPJ(false)">
                  <div @click="searchCNPJ(true)">
                    <span v-if="errorMsgCNPJ != '' || cnpj == ''" class="pointerDisabled">
                      <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                    </span>
                    <span v-else class="pointer">
                      <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                    </span>
                  </div>
                </div>
              </div>
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgCNPJ}}</span>
            </div>
          </div>
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">Nome: </label>
            </div>
            <div class="colImput">
              <input class="input textSmall" type="text" v-model="name">
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgName}}</span>
            </div>
          </div>
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">País: </label>
            </div>
            <div class="colImput">
              <div class="selectConfig">
                <div class="selectBtn">
                  <select class="textSmall" ref="selectCountry">
                    <option disabled selected hidden :value="country.id">{{country.name}}</option>
                    <option class="textSmall" v-for="(country, index) in countryList" :key="index" :value="country.id">{{country.name}}</option>
                  </select>
                  <span class="pointer">
                    <img class="verySmallIconBtn" style="transform: rotate(90deg)" src="~@/assets/icons/arrowWhite.svg" alt="">
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">Estado: </label>
            </div>
            <div class="colImput">
              <input class="input textSmall" type="text" v-model="state">
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgState}}</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">Cidade:</label>
            </div>
            <div class="colImput">
              <input class="input textSmall" type="text" v-model="city">
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgCity}}</span>
            </div>
          </div>
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">Endereço:</label>
            </div>
            <div class="colImput">
              <textarea class="input textArea textSmall" v-model="address"></textarea>
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgAddress}}</span>
            </div>
          </div>
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">CEP:</label>
            </div>
            <div class="colImput">
              <input class="input textSmall" type="text" v-model="zipCode">
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgZipCode}}</span>
            </div>
          </div>
          <div class="row">
            <div class="colLabel">
              <label class="textSmall sideFloat">Coordenadas:</label>
            </div>
            <div class="colImput">
              <input class="input textSmall" type="text" v-model="coordinates">
              <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgCoordinates}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lists">
        <div class="UnidadeArea">
          <div class="textSmall labelTableTitle">Dispositivos</div>
          <div class="inputContainer">
            <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="deviceSearch">
            <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
          </div>
          <div class="tableInternalArea">
            <table class="tableNormal textSmall">
              <tr>
                <th class="ResponsiveTableHead">Hardware ID</th>
                <th class="ResponsiveTableHead">Descrição</th>
                <th class="ResponsiveTableHead btnColumn">
                  <div @click="addDevice()" class="btnQuareIcon btnTypeSave" :class="{btnTypeDisabled: newUnity}" style="float: right">
                    <img class="verySmallIconBtn" src="@/assets/icons/outros/plusDevice.svg">
                  </div>
                </th>
              </tr>
              <tr v-for="(device, index) in deviceListFilter" :key="index">
                <td>{{device.hardwareId}}</td>
                <td>{{device.description}}</td>
                <td class="ResponsiveCellConfig">
                  <div @click="editDevice(device)" class="btnIcon btnSmallSize btnTypeSave">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
                  </div>
                  <div @click="deleteDevice(device)" class="btnIcon btnSmallSize btnTypeDelete">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/trash.svg" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="userArea">
          <div class="textSmall labelTableTitle">Usuários</div>
          <div class="inputContainer">
            <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="userSearch">
            <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
          </div>
          <div class="tableInternalArea">
            <table class="tableNormal textSmall">
              <tr>
                <th class="ResponsiveTableHead">Nome</th>
                <th class="ResponsiveTableHead">E-mail</th>
                <th class="ResponsiveTableHead btnColumn">
                  <div @click="addUser()" class="btnQuareIcon btnTypeSave" style="float: right">
                    <img class="verySmallIconBtn" src="@/assets/icons/outros/plusDevice.svg">
                  </div>
                </th>
              </tr>
              <tr v-for="(user, index) in userListFilter" :key="index">
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td class="ResponsiveCellConfig">
                  <div @click="editUser(user)" class="btnIcon btnSmallSize btnTypeSave" :class="{btnTypeDisabled: user.addFromClient}">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
                  </div>
                  <div @click="deleteUser(user)" class="btnIcon btnSmallSize btnTypeDelete" :class="{btnTypeDisabled: user.addFromClient}">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/trash.svg" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="ResponsiveBtnConfigArea">
        <button @click="save()" class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Salvar</button>
        <button @click="close()" class="btn btnNormalSize btnTypeCancel">Voltar</button>
      </div>
      <PopUp msg="Salvando Unidade" :show="loadData" type="Loader"/>
      <PopUp :msg="faultMsg" :show="faultOnUpdateUnity" type="Fault" :onOk="popUpOk"/>
    </div>
    <DeviceEdit v-if="deviceEdit" :device="deviceToEdit" :unityEdit="true" @close="deviceEdit = false, unityEdit = true"></DeviceEdit>
    <NewUser v-if="newUser" :componet="true" @close="newUser = false, unityEdit = true, showPopupFilterList = true"></NewUser>
    <FilterList
      :type="filterListType"
      :show="showPopupFilterList"
      :list="listToFilter"
      @close="showPopupFilterList = false"
      @addItems="fillList($event), showPopupFilterList = false"
      @newUserView="newUser = true, unityEdit = false, showPopupFilterList = false">
    </FilterList>
    <UserPermission :show="showPopupPermissionUser" :user="userEdit" @close="showPopupPermissionUser = false"></UserPermission>
    <SearchInList v-if="showPopupFindClient" @close="showPopupFindClient = false" :title="'Clientes'" :list="clientList" @selected="fillClient($event), showPopupFindClient = false"></SearchInList>
  </div>
</template>

<script>
  import PopUp from '@/views/PopUp/PopUp.vue'
  import FilterList from "../popups/FilterList"
  import UserPermission from "../popups/UserPermission"
  import SearchInList from "../popups/SearchInList"
  import ClientDAO from '@/sys/Control/Client/ClientDAO'
  import UnityDAO from '@/sys/Control/Unity/UnityDAO'
  import DeviceDAO from '@/sys/Control/Device/DeviceDAO'
  import UserDAO from '@/sys/Control/User/UserDAO'
  import NewUser from '../Users/UserEdit'
  import DeviceEdit from '../Devices/DeviceEdit'

  export default {
    components: { FilterList, UserPermission, SearchInList, NewUser, DeviceEdit, PopUp },
    props:{
      unity: Object,
      newUnity: Boolean,
      clientEdit: Boolean
    },
    methods: {
      removeItemList(item, list) {
        for(let i=0; i < list.length; i++) {
          if(item._id == list[i]._id) {
            list.pop(i);
            break;
          }
        }
      },
      compareAddFromClient(user) {
        if(user.addFromClient) {
          return -1;
        } else {
          return 0;
        }
      },
      compareUser(user, userList) {
          for(let userAux of userList) {
              if(user.email == userAux.email) {
                  return true;
              }
          }
          return false;
      },
      modifyUserList(userList){
        for(let i=0; i < userList.length; i++) {
          if(userList[i].addFromClient == null) {
            userList[i].addFromClient = false
          }
          userList[i] = {
            _id: userList[i]._id,
            realmId: userList[i].realmId,
            name: userList[i].name,
            email: userList[i].email,
            accessLevel: userList[i].accessLevel,
            permissions: userList[i].permissions,
            addFromClient: userList[i].addFromClient
          }
        }
      },
      formatCNPJ(){
        var x = this.cnpj.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        this.cnpj = (x[2] ? x[1] + '.' + x[2] : x[1]) + (x[3] ? '.' + x[3] : '') + (x[4] ? '/' + x[4] : '') + (x[5] ? '-' + x[5] : '');
      },
      searchCNPJ(completeData){
        if(this.cnpj != '' && this.errorMsgCNPJ == ''){
          console.log('Searching CNPJ...');
          let strCNPJ = this.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
          this.getDataCNPJ(strCNPJ, completeData);
        }
      },
      getDataCNPJ(strCNPJ, completeData){
        var $jsonp = (function(){
          var that = {};

          that.send = function(src, options) {
            var callback_name = options.callbackName || 'callback',
              on_success = options.onSuccess || function(){},
              on_timeout = options.onTimeout || function(){},
              timeout = options.timeout || 5; // sec

            var timeout_trigger = window.setTimeout(function(){
              window[callback_name] = function(){};
              on_timeout();
            }, timeout * 1000);

            window[callback_name] = function(data){
              window.clearTimeout(timeout_trigger);
              on_success(data);
            }

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = src;

            document.getElementsByTagName('head')[0].appendChild(script);
          }

          return that;
        })();

        let self = this
        $jsonp.send('https://www.receitaws.com.br/v1/cnpj/' + strCNPJ + '?callback=handleStuff', {
          callbackName: 'handleStuff',
          onSuccess: function(json){
            if(json.status != 'ERROR') {
              self.errorMsgCNPJ = ''
              if(completeData) {
                self.name = json.nome;
                self.state = json.uf;
                self.city = json.municipio;
                self.address = json.logradouro + ' ' + json.numero + ', ' + json.complemento + ' - ' + json.bairro;
                self.zipCode = json.cep;
              }
              console.log('Valid CNPJ!!', json);
            } else {
              self.errorMsgCNPJ = 'CNPJ inválido'
              console.log('Invalid CNPJ!!', json);
            }
          },
          onTimeout: function(){
            self.errorMsgCNPJ = ''
            console.log('Timeout CNPJ!!');
          },
          timeout: 5
        });
      },
      async checkCNPJUsed(){
        if(this.errorMsgCNPJ == '') {
          if(this.cnpj.length == 18) {
            if(this.cnpj != this.cnpjAux) {
              let unityCNPJ = await UnityDAO.readUnity(this.cnpj);
              if(unityCNPJ) {
                  this.errorMsgCNPJ = 'Este CNPJ já está em uso';
              } else {
                  this.errorMsgCNPJ = '';
              }
            } else {
              this.errorMsgCNPJ = '';
            }
          } else {
            this.errorMsgCNPJ = 'Insira um CNPJ válido';
          }
        }
      },
      async save(){
        if(this.client.name == ''){
          this.errorMsgClient = 'Preencha este campo'
        } else {
          this.errorMsgClient = ''
        }
        if(this.name == ''){
          this.errorMsgName = 'Preencha este campo'
        } else {
          this.errorMsgName = ''
        }
        if(this.cnpj == ''){
          this.errorMsgCNPJ = 'Preencha este campo'
        } else {
          await this.checkCNPJUsed();
        }
        if(this.state == ''){
          this.errorMsgState = 'Preencha este campo'
        } else {
          this.errorMsgState = ''
        }
        if(this.city == ''){
          this.errorMsgCity = 'Preencha este campo'
        } else {
          this.errorMsgCity = ''
        }
        if(this.address == ''){
          this.errorMsgAddress = 'Preencha este campo'
        } else {
          this.errorMsgAddress = ''
        }
        if(this.zipCode == ''){
          this.errorMsgZipCode = 'Preencha este campo'
        } else {
          this.errorMsgZipCode = ''
        }
        if(this.coordinates == ''){
          this.errorMsgCoordinates = 'Preencha este campo'
        } else {
          this.errorMsgCoordinates = ''
        }
        if(this.errorMsgClient == '' && this.errorMsgName == '' && this.errorMsgCNPJ == '' && this.errorMsgCity == '' && this.errorMsgAddress == ''
            && this.errorMsgZipCode == '' && this.errorMsgCoordinates == ''){

          this.loadData = true;

          this.modifyUserList(this.userList);

          this.client = {
            _id: this.client._id,
            name: this.client.name
          }

          for(let i=0; i < this.deviceList.length; i++) {
            this.deviceList[i] = {
              _id: this.deviceList[i]._id,
              type: this.deviceList[i].type,
              hardwareId: this.deviceList[i].hardwareId,
              description: this.deviceList[i].description,
              gpsCoordinates: this.deviceList[i].gpsCoordinates
            }
          }

          if(this.newUnity){
            await UnityDAO.createUnity({
              client: this.client,
              name: this.name.trim(),
              cnpj: this.cnpj,
              address: this.address,
              city: this.city,
              state: this.state,
              country: this.selectedCountry(),
              zipCode: this.zipCode,
              gpsCoordinates: this.coordinates,
              license: null,
              deviceList: this.deviceList,
              userList: this.userList,
              createdAt: new Date(),
              updatedAt: new Date(),
              deletedAt: null
            }).catch(e=>this.popUpException(e));
          }else{
            let unity = {
              _id: this.unity._id,
              client: this.client,
              name: this.name.trim(),
              cnpj: this.cnpj,
              address: this.address,
              city: this.city,
              state: this.state,
              country: this.selectedCountry(),
              zipCode: this.zipCode,
              gpsCoordinates: this.coordinates,
              license: null,
              deviceList: this.deviceList,
              userList: this.userList,
              createdAt: this.unity.createdAt,
              updatedAt: new Date(),
              deletedAt: this.unity.deletedAt
            }

            if(this.client.name != this.clientAux.name) {
              ClientDAO.addClientUnity(unity).catch(e=>this.popUpException(e));
              if(this.clientAux.name != '') {
                ClientDAO.removeClientUnity(this.unityAux).catch(e=>this.popUpException(e));
              }
            }

            await UnityDAO.updateUnity(unity).catch(e=>this.popUpException(e));

            if(this.removeDeviceList.length > 0) {
              DeviceDAO.removeDeviceUnity(this.removeDeviceList).catch(e=>this.popUpException(e));
            }

            if(this.addDeviceList.length > 0) {
              DeviceDAO.addDeviceUnity(unity, this.addDeviceList).catch(e=>this.popUpException(e));
            }
          }
          this.loadData = false
          if(!this.faultOnUpdateUnity) {
            this.$emit('close');
          }
        }
      },
      close(){
        this.$emit('close')
      },
      addUser(){
        this.listToFilter = this.userList
        this.filterListType = this.enumListType.user
        this.showPopupFilterList = true
      },
      async editUser(user){
        if(!user.addFromClient) {
          this.userEdit = await UserDAO.readUser(user.email);
          this.showPopupPermissionUser = true
        }
      },
      deleteUser(user){
        if(!user.addFromClient) {
          user.select = false;
          this.userList = this.userList.filter( currentUser => { return currentUser.email != user.email })
        }
      },
      addDevice(){
        if(!this.newUnity){
          this.listToFilter = this.deviceList
          this.filterListType = this.enumListType.device
          this.showPopupFilterList = true
        }
      },
      async editDevice(device){
        this.deviceToEdit = await DeviceDAO.readDevice(device.hardwareId)
        this.deviceEdit = true
        this.unityEdit = false
      },
      deleteDevice(device){
        device.select = false
        this.removeItemList(device, this.addDeviceList)
        this.removeDeviceList.push(device)
        this.deviceList = this.deviceList.filter( currentDevice => { return currentDevice.hardwareId != device.hardwareId })
      },
      showPopup(){
        if(!this.clientEdit){
          this.showPopupFindClient = true
        }
      },
      selectedCountry(){
        return this.countryList.find(country => country.id == this.$refs.selectCountry.value);
      },
      fillClient(client){
        this.client = client
      },
      fillList(items){
        if(this.enumListType.user == this.filterListType){
          for (const user in items) {
            this.userList.push(items[user])
          }
        }else if(this.enumListType.device == this.filterListType){
          for (const device in items) {
            this.deviceList.push(items[device])
            this.addDeviceList.push(items[device])
            this.removeItemList(items[device], this.removeDeviceList)
          }
        }
      },
      popUpOk: function(){
        this.faultOnUpdateUnity=false;
        this.$emit('close');
      },
      popUpException: function(e){
        this.loadData = false;
        this.faultMsg=e;
        this.faultOnUpdateUnity=true;
      }
    },
    computed:{
      userListFilter(){
        if(this.userList.length > 0) {
          return this.userList.filter(user => {
            return user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
              user.email.toLowerCase().includes(this.userSearch.toLowerCase())
          })
        }
      },
      deviceListFilter(){
        if(this.deviceList.length > 0) {
          return this.deviceList.filter(device => {
            return device.hardwareId.toLowerCase().includes(this.deviceSearch.toLowerCase()) ||
              device.description.toLowerCase().includes(this.deviceSearch.toLowerCase())
          })
        }
      }
    },
    watch:{
      cnpj(){
        this.errorMsgCNPJ = ''
      },
      client(){
        if(this.client.userList) {
          this.userList = this.userList.filter(user => !user.addFromClient);
          for(let i=0; i < this.client.userList.length; i++) {
            if(!this.compareUser(this.client.userList[i], this.userList)) {
              this.userList.splice(0, 0, this.client.userList[i]);
            }
          }
        }
      }
    },
    data() {
      return {
        unityAux: null,
        client: {name: ''},
        clientAux: {name: ''},
        clientList: null,
        country: {id: 0, name: 'Brasil'},
        countryList: [{id: 0, name: 'Brasil'}, {id: 1, name: 'Agentina'}, {id: 2, name: 'EUA'}, {id:3, name: 'México'} ],
        name: '',
        cnpj: '',
        cnpjAux: '',
        coordinates: '',
        state: '',
        city: '',
        address: '',
        zipCode: '',
        license: null,
        errorMsgClient: '',
        errorMsgName: '',
        errorMsgCNPJ: '',
        errorMsgState: '',
        errorMsgCity: '',
        errorMsgAddress: '',
        errorMsgZipCode: '',
        errorMsgCoordinates: '',
        userSearch: '',
        deviceSearch: '',
        showPopupFilterList: false,
        showPopupPermissionUser: false,
        showPopupFindClient: false,
        userEdit: null,
        userList: [],
        deviceList: [],
        addDeviceList: [],
        removeDeviceList: [],
        listToFilter: [],
        filterListType: null,
        enumListType: {user: 1, unity: 2, device: 3},
        unityEdit: true,
        newUser: false,
        deviceEdit: false,
        deviceToEdit: null,
        title: '',
        loadData: false,
        faultOnUpdateUnity: false,
        faultMsg: null
      };
    },
    async created() {
      if(this.unity){
        this.title = 'Editar Unidade'
        if(this.unity.client != null) {
          this.client = this.unity.client
          this.clientAux = this.client
        }
        this.unityAux = this.unity
        this.name = this.unity.name
        this.cnpj = this.unity.cnpj
        this.cnpjAux = this.cnpj
        this.address = this.unity.address
        this.city = this.unity.city
        this.state = this.unity.state
        this.country = this.countryList.find(country => country.id == this.unity.country.id)
        this.zipCode = this.unity.zipCode
        this.coordinates = this.unity.gpsCoordinates
        this.license = this.unity.license
        this.deviceList = this.unity.deviceList
        this.userList = this.unity.userList.reverse().sort(this.compareAddFromClient)
      }else{
        this.title = 'Nova Unidade'
      }
      this.clientList = await ClientDAO.readAllActiveClients();
    }
  };
</script>

<style scoped>
  .textArea{
    padding: 5px;
    height: 141px;
    resize: none;
  }
  .inputDisabled{
    background-color: #757575;
  }
  .sideFloat{
    float: right;
  }
  .form{
    display: table;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
  }
  .column{
    float: left;
    width: 50%;
  }
  .row{
    width: 100%;
    height: 85px;
  }
  .colLabel{
    width: 30%;
    float: left;
    padding: 5px 30px 5px 0;
    display: table-cell;
    vertical-align: top;
  }
  .colImput{
    position: relative;
    width: 70%;
    float: left;
    padding: 0 5px;
    display: table-cell;
    vertical-align: top;
    margin-bottom: 30px
  }
  .lists{
    display: inline-block;
    padding: 0 10px;
    width: 100%
  }
  .UnidadeArea{
    position: relative;
    width: 50%;
    height: 600px;
    float: left;
    margin-bottom: 15px;
  }
  .userArea{
    position: relative;
    width: 49%;
    height: 600px;
    float: right;
  }
  .tableInternalArea {
    position: absolute;
    bottom: 0px;
    top: 45px;
    left: 0;
    right: 0;
    background-color: #1e2227;
    overflow: auto;
  }
  @media(max-width: 1700px){
    .form{
      width: 90%;
      margin-left: 5%;
    }
    .column{
      width: 100%;
    }
    .colLabel{
      width: 35%;
    }
    .colImput{
      width: 65%;
      margin-bottom: 40px
    }
    .UnidadeArea{
      width: 100%
    }
    .userArea{
      width: 100%
    }
  }
  @media(max-width: 1000px){
    .colLabel{
      width: 28%;
    }
  }
  @media(max-width: 825px){
    .colLabel{
      width: 100%;
    }
    .colImput{
      width: 100%;
      margin-bottom: 20px
    }
    .sideFloat{
      float: left
    }
    .lists{
      padding: 0 5px;
    }
  }
</style>