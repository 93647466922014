<template>
    <dir>
        <transition name="fadeShadow">
            <div v-if="show" class="backShadow"></div>
        </transition>
        <transition>
            <div v-if="show" class="popupArea normalPopup">
                <div class="areaWindow">
                    <div class="title">
                        <label class="textAverage">{{title}}</label>
                    </div>
                    <div class="inputContainer">
                        <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="itemSearch">
                        <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
                    </div>
                    <div class="tableInternalArea">
                        <table class="tableNormal textSmall">
                            <tr>
                                <th class="ResponsiveTableHead">{{subTitle}}</th>
                                <th v-if="type == enumListType.device" class="ResponsiveTableHead">Descrição</th>
                                <th v-if="type == enumListType.unity" class="ResponsiveTableHead">CNPJ</th>
                                <th v-else-if="type == enumListType.device" class="ResponsiveTableHead">Tipo</th>
                                <th v-else-if="type == enumListType.user" class="ResponsiveTableHead">E-mail</th>
                                <th class="ResponsiveTableHead btnSmallColumn">
                                    <div v-if="type == enumListType.user" @click="newUser()" class="btnQuareIcon btnTypeSave" style="float: right">
                                        <img class="verySmallIconBtn" src="@/assets/icons/outros/plusDevice.svg">
                                    </div>
                                </th>
                            </tr>
                            <tr v-for="(item, index) in filteredList" :key="index">
                                <td v-if="type == enumListType.device">{{item.hardwareId}}</td>
                                <td v-if="type == enumListType.device">{{item.description}}</td>
                                <td v-else>{{item.name}}</td>
                                <td v-if="type == enumListType.unity">{{item.cnpj}}</td>
                                <td v-else-if="type == enumListType.device">{{item.type.name}}</td>
                                <td v-else-if="type == enumListType.user">{{item.email}}</td>
                                <td>
                                    <div style="display: flex; float: right; margin-right: 25px">
                                        <label class="checkbox bounce">
                                            <input type="checkbox" v-model="item.select">
                                            <svg viewBox="0 0 21 21">
                                                <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                            </svg>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="loaderPosition">
                            <div v-if="listLoading" class="Loader"/>
                        </div>
                    </div>
                </div>
                <div class="btnArea">
                    <button @click="comeBack()" class="btn btnNormalSize btnTypeCancel">Cancelar</button>
                    <button @click="add()" class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Adicionar</button>
                </div>
            </div>
        </transition>
    </dir>
</template>

<script>
    import UserDAO from '@/sys/Control/User/UserDAO'
    import UnityDAO from '@/sys/Control/Unity/UnityDAO'
    import DeviceDAO from '@/sys/Control/Device/DeviceDAO'

    export default {
        components:{},
        props:{
            show: Boolean,
            type: Number,
            list: Array
        },
        methods: {
            newUser(){
                this.$emit('newUserView')
            },
            add(){
                let selectedItems = []
                selectedItems = this.currentList.filter( item => { return item.select == true })
                this.$emit('addItems', selectedItems)
            },
            comeBack(){
                this.$emit('close')
            }
        },
        computed:{
            filteredList(){
                return this.currentList.filter(item => {
                    if(this.type == this.enumListType.user) {
                        return item.name.toLowerCase().includes(this.itemSearch.toLowerCase())
                    }else if(this.type == this.enumListType.unity) {
                        return item.name.toLowerCase().includes(this.itemSearch.toLowerCase()) ||
                            item.cnpj.toLowerCase().includes(this.itemSearch.toLowerCase())
                    }else if(this.type == this.enumListType.device) {
                        return item.hardwareId.toLowerCase().includes(this.itemSearch.toLowerCase()) ||
                            item.type.name.toLowerCase().includes(this.itemSearch.toLowerCase()) ||
                            item.description.toLowerCase().includes(this.itemSearch.toLowerCase())
                    }
                })
            }
        },
        watch:{
            async show(){
                if(this.show){
                    this.listLoading = true
                    this.currentList = []

                    if(this.type == this.enumListType.user) {
                        this.title = 'Adicionar Usuários'
                        this.subTitle = 'Usuários Confirmados'
                        this.userList = await UserDAO.readAllConfirmedUsers()

                        this.currentList = this.userList.filter(user => {
                            return this.list.find(currentUser => user.email == currentUser.email) == null;
                        })

                    }else if (this.type == this.enumListType.unity) {
                        this.title = 'Adicionar Unidades'
                        this.subTitle = 'Nome'
                        this.unityList = await UnityDAO.readAllAvailableUnities()

                        this.currentList = this.unityList.filter(unity => {
                            return this.list.find(currentUnity => unity.cnpj == currentUnity.cnpj) == null;
                        })
                    }else if (this.type == this.enumListType.device) {
                        this.title = 'Adicionar Dispositivos'
                        this.subTitle = 'Hardware ID'
                        this.deviceList = await DeviceDAO.readAllAvailableDevices()

                        this.currentList = this.deviceList.filter(device => {
                            return this.list.find(currentDevice => device.hardwareId == currentDevice.hardwareId) == null;
                        })
                    }
                    this.listLoading = false;
                }
            }
        },
        data(){
            return{
                itemSearch: '',
                userList: [],
                unityList: [],
                deviceList: [],
                currentList: [],
                title: '',
                subTitle: '',
                enumListType: {user: 1, unity: 2, device: 3},
                listLoading: true
            }
        },
        created(){ }
    }
</script>

<style scoped>
    .tableInternalArea {
        position: absolute;
        bottom: 80px;
        top: 130px;
        left: 10px;
        right: 10px;
        background-color: #1e2227;
        overflow: auto;
    }
    .tableNormal td{
        padding: 15px 3px;
        text-align: center;
    }
    .backShadow {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
    }
    .areaWindow{
        position: absolute;
        padding: 10px;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #292d32;
        box-shadow: 1px 0px 50px 20px rgba(0, 0, 0, .8);
        font-family: Helvetica, Arial, sans-serif;
    }
    .input{
        width: 400px;
        height: 40px;
        padding-left: 8px;
        background-color: #1e2227;
        border: none;
        outline: none;
        border-radius: 4px 4px 4px 4px;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
    }
    .formArea{
        position: absolute;
        top: 70px;
        width: 685px;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        height: 200px;
    }
    .form{
        display: table;
        width: 100%;
    }
    .row{
        display: table-row;
        height: 70px;
    }
    .col40{
        width: 40%;
        padding: 12px 15px;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
    }
    .col60{
        width: 60%;
        padding: 12px 5px;
        text-align: left;
        display: table-cell;
    }
    .btnArea{
        margin-top: 15px;
        position: absolute;
        bottom: 15px;
        right: 12px
    }
    .imgSelectBtn{
        width: 12px;
        transform: rotate(90deg);
    }
    .inputBtn{
        float: right;
        padding: 10px;
        background: #4375EF;
        border: none;
        border-left: none;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
        padding: 10px 17px 2px 17px
    }
    .iconSearch{
        width: 25px
    }
    .deviceArea{
        position: absolute;
        top: 100px;
        right: 10px;
        left: 10px;
        bottom: 80px;
        background-color: #1e2227;
        overflow-y: auto
    }
    @media (orientation: landscape) and (max-width: 825px) {
        .normalPopup{
            height: 100%;
            width: 400px;
        }
    }
    @media(max-width: 825px){
        .areaWindow{
            padding: 5px;
        }
        .tableInternalArea {
            bottom: 70px;
            top: 100px;
            left: 5px;
            right: 5px
        }
        .inputSearch{
            margin-top: 0;
        }
        .searchIcon{
            top: 8px;
        }
    }
</style>